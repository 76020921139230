import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";
import { Navigate, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faClipboardCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function List({ date }) {

  const history = useNavigate();
  const [, setRole] = useState();
  const [loading, setLoading] = useState(false);
  const [getList, setGetList] = useState([]);

  useEffect(() => {

    const token = GetToken();
    if (token === "null") {
      return <Navigate to="/" />;
    }

    if (!token) {
      return <Navigate to="/" />;
    }

    var userInfo = {};
    try {
      userInfo = jwt_decode(token);
      setRole(userInfo.role);
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    if (userInfo.chgpss === 1) {
      return null;
    }

    UpdateList();

  }, []);

  function UpdateList() {
 
    if (!date) {
      return;
    }

    setLoading(true);

    api.post('/v1/charge/getbyuserdate', { date: date }, { headers: { "x-access-token": GetToken() }})
      .then(getlist => {
        if (!getlist.data.res) {
        } else {
          setGetList(getlist.data.message);
        }
      }).catch(_ => {
        console.log(_);
      })
      .finally(() => { 
        setLoading(false);
      });
  }

  function handleClick(item) {
    const newList = getList.map((qrcode) => {
      if (qrcode.id === item.id) {
        return {
          ...qrcode,
          isBlur: !qrcode.isBlur
        };
      } else {
        return qrcode;
      }
    });
    setGetList(newList);
  }

  function handleCopy(item) {
    var newList = getList.map((qrcode) => {
      if (qrcode.id === item.id) {
        return {
          ...qrcode,
          isCopied: !qrcode.isCopied
        };
      } else {
        return qrcode;
      }
    });

    setGetList(newList);
    navigator.clipboard.writeText(item.qrcode);

    setTimeout(() => {
      newList = getList.map((qrcode) => {
        if (qrcode.id === item.id) {
          return {
            ...qrcode,
            isCopied: qrcode.isCopied
          };
        } else {
          return qrcode;
        }
      });
      setGetList(newList);
    }, 2000);
  }

  function gotoDetails(txid) {
    history("/dashboard/pix/" + txid);
  }

  function QRCode({ items, onClick, onCopy }) {
    return (
      <div className="d-flex flex-wrap col-md-12 col-12">
        {items.map((item) => (
          <div className="card text-white bg-secondary" style={{width: "20rem", margin: "10px"}} key={item.id}>
            <div className="card-header text-center">
              {item.status !== "CONCLUIDA" ? "AGUARDANDO PAGAMENTO" : "PAGO"}
            </div>
            <div className={ "card-body text-center "  + (item.status !== "CONCLUIDA" ? "bg-warning text-muted " : "bg-success text-white ") }>
              {!item.qrcodeimage ? <p>Sem Imagem</p> :
                <img className="card-img-top" src={ !item.qrcodeimage ? "" : "data:image/png;base64," + item.qrcodeimage } style={{ filter: item?.isBlur ? "blur(0px)" : "blur(4px)"}} type="button" onClick={() => onClick(item)} alt="QR Code" />
              }
              <span>{ item?.isBlur ? "Clique para borrar" : "Clique para aparecer" }</span>
            </div>
            <div className="card-footer">
              <p className="card-text">
                Data: {item.creation}<br />
                Valor: {parseFloat(item.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}<br />
                Descrição: {item.sellorder}
              </p>
              <div className="d-flex justify-content-between card-text">
                <button type="submit"
                    className={"btn btn-sm ".concat(item?.isCopied ? "bg-white" : "bg-white")}
                    onClick={ () => gotoDetails(item.txid) }
                >
                    <FontAwesomeIcon id="clipboard" icon={ faInfoCircle } size="lg" className="btn-icon mr-4" />Detalhes
                </button>
                <button type="submit"
                    className={"btn btn-sm ".concat(item?.isCopied ? "bg-white" : "bg-white")}
                    onClick={ () => onCopy(item) }
                >
                    <FontAwesomeIcon id="clipboard" icon={ item?.isCopied ? faClipboardCheck : faCopy } size="lg" className="btn-icon mr-4" />{ item?.isCopied ? "Copiado!" : "Copiar" }
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  //CORES
  //#0000BF #3F7F00 #82FF05 #FBE731 #F60404"
  return (
    <div>
      <Loading visible={loading} />
      <div className="row">
        <QRCode items={getList} onClick={ handleClick } onCopy={ handleCopy } />
      </div>
    </div>
  );
}