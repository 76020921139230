import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import {
  faAngleLeft,
  faAngleRight,
  faChartPie,
  faCircleInfo,
  faMoneyBills,
  faRefresh
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
//import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

import api, { GetToken } from "../../api/axios";
import Loading from "../../components/Loading";

export default function List({ date, searchTerm }) {
  const history = useNavigate();
  const [charges, setCharges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [, setSelected] = useState(0);
  const [sum, setSum] = useState(0.00);
  
  useEffect(() => {

    const token = GetToken();
    if (token === "null") {
      return <Navigate to="/" />;
    }

    if (!token) {
      return <Navigate to="/" />;
    }

    var userInfo = {};
    try {
      userInfo = jwt_decode(token);
      //setRole(userInfo.role);
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    /*if (userInfo.role !== 0) {
      history("/dashboard");
      return true;
    }*/

    if (userInfo.chgpss === 1) {
      return null;
    }

    UpdateList();
    
  }, []);

  function UpdateList() {

    if (!date) {
      return;
    }

    return;

    setLoading(true);

    api.post('/v1/charge/getbyuserdate', { date: date }, { headers: { "x-access-token": GetToken() }})
      .then((data) => {
        const normalize = data.data.message.map((x) => {
          if (!x.sellorder) x.sellorder = "";
          return x;
        });
        var totalSum = 0.00;
        const SearchTerm = searchTerm.toLowerCase();
        const filter = normalize.filter((x) => 
          x.sellorder.toLowerCase().includes(SearchTerm) || x.name.toLowerCase().includes(SearchTerm)
        );

        const slice = filter.slice(offset, offset + perPage);
        setCharges(slice);
        setPageCount(Math.ceil(filter.length / perPage));

        if (slice.length > 0) {
          slice.forEach(element => {
            if (element.status === "CONCLUIDA") {
              totalSum += parseFloat(element.amount);
            }
          });
        }
        setSum(totalSum);

      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    let offset = Math.ceil(selectedPage * perPage);
    setOffset(offset);
    setSelected(selectedPage);
  };

  function refresh() {
    UpdateList();
  }

  return (
    <div>
      <Loading visible={loading} />

      <div className="row mb-3">
        <div className="col-sm-3">
          <div className="table-list p-3">
            <h5 style={{ color: "#A6A6A6", fontSize: "14px" }}>Box</h5>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faChartPie}
                color={"#85BB65"}
                size="2x"
              />
              <h5 style={{ color: "black", fontSize: "18px", margin: "2px 0 0 15px", fontWeight: 700 }}>0</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="row p-3 d-flex justify-content-end">
        <button className="btn btn-orange col-md-1" onClick={ (e) => refresh() }>
          <FontAwesomeIcon icon={faRefresh} className="btn-icon" />
          Update
        </button>
      </div>
      <div className="table-container">
        <table className="table table-list btn100">
          <thead>
            <tr>
              <th scope="col">
                Name
              </th>
              <th scope="col">
                Phone Number
              </th>
              <th scope="col">
                Company
              </th>
              <th scope="col"> 
                Job Title
              </th>
              <th scope="col">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {charges.map((charge) => {
              return (
                <tr key={charge.id}>
                  <td>{ charge.creation }</td>
                  <td>{ charge.sellorder }</td>
                  <td>{ charge.name }</td>
                  <td>{ parseFloat(charge.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                  <td>
                    <div
                      className="table-status"
                      style={{
                        backgroundColor: charge.status !== "CONCLUIDA"
                          ? "#ffc107"
                          : "#28a745",
                      }}>
                      { charge.status }
                    </div>
                  </td>
                  <td className='icon-cell'>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      size="lg"
                      onClick={() => history("/dashboard/pix/" + charge.txid)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={ (e) => handlePageClick() }
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
}
