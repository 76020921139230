import React, { useEffect, useRef, useState,  } from "react";
import api, { GetToken } from "../api/axios";
import { Navigate, useNavigate } from "react-router-dom";
//import jwt_decode from "jwt-decode";
import Loading from "../components/Loading";
import jwt_decode from "jwt-decode";
import mqtt from "mqtt";
import useSound from "use-sound";
import sound from "../assets/sounds/moon_drop.mp3";
import Swal from "sweetalert2";
import { faClose, faPaperPlane, faPenToSquare, faSmile, faCommentAlt, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatItemList from "../components/ChatItemList";
import ChatItem from "../components/ChatItem";
import ContactItemList from "../components/ContactItemList";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TenantState, LineState, MqttState } from '../state/states';
import { usePageVisibility } from 'react-page-visibility';

export default function Create() {

  /*const tenantData = useRecoilValue(TenantState);
  const lineData = useRecoilValue(LineState);*/

  const [ tenantData, setTenantData ] = useRecoilState(TenantState);
  const [ lineData, setLineData ] = useRecoilState(LineState);
  const [ mqttData, setMqttData ] = useRecoilState(MqttState);
  const isVisible = usePageVisibility();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageList, setPageList] = useState(1);
  const [pageDetails, setPageDetails] = useState(1);
  const [offset, setOffset] = useState(5);
  const [contactId, setContactId] = useState(0);
  const [details, setDetails] = useState([]);
  const [list, _setList] = useState([]);
  const [state, setState] = useState(false);
  const [text, setText] = useState("");
  const [name, setName] = useState("");
  const [newConv, setNewConv] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [searchText, setSearchText] = useState("");
  //const [tenantId, setTenantId] = useState(tenantData);
  const [client, setClient] = useState();
  const [payload, setPayload] = useState();
  const [chat, setChat] = useState();
  const [sent, setSent] = useState();
  const [userInfo, setUserInfo] = useState();
  const [ topic, setTopic ] = useState();
  const [play] = useSound(sound, {
    // `interrupt` ensures that if the sound starts again before it's
    // ended, it will truncate it. Otherwise, the sound can overlap.
    interrupt: true,
  });

  const listRef = useRef(list);
  const setList = data => {
    listRef.current = data;
    _setList(data);
  };

  /*const lineRef = useRecoilValue(LineState);
  const setLineData = data => {
    lineRef.current = data;
    _setLineData(data);
  };*/
  
  useEffect(() => {
    if (tenantData && lineData && mqttData) {
      const options={
        port: mqttData.port,
        clientId: "cli_" + Math.random().toString(16).substr(2, 8),
        username: mqttData.user,
        password: mqttData.pass,
        clean:true
    
      };
    
      setTopic(`${tenantData}/${lineData.id}/#`);
      setClient(mqtt.connect(mqttData.host, options));
      handleRefresh();

    }
  }, [tenantData, lineData, mqttData]);

  useEffect(() => {
    document.title = "LoadOut Chat - Chat";
    const token = GetToken();
    if (token === "null") {
      return <Navigate to="/" />;
    }

    if (!token) {
      return <Navigate to="/" />;
    }

    var userinfo = {};
    try {
      userinfo = jwt_decode(token);
      setUserInfo(userinfo);
      //setRole(userInfo.role);
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    if (userinfo.chgpss === 1) {
      return null;
    }

    //document.addEventListener("visibilitychange", onFocus);
  
    return function cleanup() {
      //document.removeEventListener("visibilitychange", onFocus);
    }
    
  }, []);

  //async function onFocus() {
    //if (!document.hidden) {
    useEffect(() =>{
        //Getting the last chatid from list
        if (isVisible) {

          if (list.length === 0) {
            return;
          }

        //const lastChatId = listRef?.current[0].id;
        const lastChatId = list[0]?.id;

        api.get(`/v1/chat/check/${lastChatId}/line/${lineData.id}`,
          { headers: { "x-access-token": GetToken() } }
        )
          .then((getcheck) => {
            if (getcheck.data.res) {
              if (getcheck.data.ret[0].count > 0) {
                play();
                Swal.fire({
                  icon: "warning",
                  title: "Updates!",
                  text: "There are new conversations, please refresh your list."
                });
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: getcheck.data.message
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: JSON.stringify(err),
            });
          });
        } else {
          console.log("sumiu!");
        }
    }, [isVisible]);
    //}
  //}
  
  useEffect(() => {
    if (client && !client.connected) { 
      client.on('connect', function () {
        console.log("conectou!");
        client.subscribe(topic, function (err) {
          console.log("inscreveu-se!");
          if (err) {
            console.log("erro na inscrição!");
          }          
        });

      });

      client.on('disconnect', function () {
        console.log("desconectou!");
      });

      client.on('message', function (topic, message) {
        // message is Buffer
        //tenant/lineid/2/contactid/?/status = 0 or 1
        console.log(`${topic.toString()}: ${message.toString()}`);
        if (String(topic).indexOf("/chat") > 0) {
          console.log(`Chat: ${message}`);
          setPayload(JSON.parse(message));
        };
        if (String(topic).indexOf("/status") > 0) {
          console.log(`Status: ${message}`);
          setChat(JSON.parse(message));
        };
        if (String(topic).indexOf("/sent") > 0) {
          console.log(`Sent: ${message}`);
          setSent(JSON.parse(message));
        };
      });

      /*client.publish('presence', 'Hello mqtt',{
        retain:false,
        qos:1});*/      

      return function cleanup() {
        client.end();
        console.log("Desconectou!");
      }

    }
  }, [client]);

  //Chat topic from mqtt
  useEffect(() => {
    if (payload) {
      console.log(list.length);

      (async () => {
        await api.get(`/v1/chat/getbyid/${payload.chatid}`,
          { headers: { "x-access-token": GetToken() } }
        )
          .then((getbyid) => {
            if (getbyid.data.res) {
              console.log(getbyid);
              var newList = list.filter(item => item.contactid !== getbyid.data.ret[0].contactid);
              newList = [getbyid.data.ret[0], ...newList];
              setList(newList);

              if (getbyid.data.ret[0].contactid === contactId) {
                const listDetails = details.filter(item => item.id !== getbyid.data.ret[0].id)
                const newDetails = [...listDetails, getbyid.data.ret[0]];
                setDetails(newDetails);
                //If outgoing, not set status
                if (payload.notify) {
                  setStatus(getbyid.data.ret[0].lineid, getbyid.data.ret[0].contactid, getbyid.data.ret[0].id);
                }
              }

              //If incoming, notify play
              if (payload.notify) {
                play();
              }

            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: getbyid.data.message
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: JSON.stringify(err),
            });
          });
      })();
    }
  }, [payload]);

  async function setStatus(pLine, pContact, pChat) {
    await api.post(`/v1/chat/status`,
      {
        lineid: pLine,
        contactid: pContact,
        chatid: pChat
      },
        { headers: { "x-access-token": GetToken() } }
      )
      .then((setstatus) => {
        if (!setstatus.data.res) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: setstatus.data.message
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.stringify(err),
        });
      });
  }

  //Status topic from mqtt
  useEffect(() => {
    if (chat) {
      console.log(chat);

      var newList = list.filter(item => {
        if (item.contactid === chat.contactid) {
          item.readstatus = chat.readstatus;
        }
        return item;
      });
      setList(newList);
    }
  }, [chat]);

  //Sent topic from mqtt
  useEffect(() => {
    if (sent) {
      console.log(sent);

      if (contactId !== sent.contactid) {
        return;
      }

      var newDetails = details.filter(item => {
        if (item.id === sent.chatid) {
          item.readstatus = sent.readstatus;
        }
        return item;
      });
      setDetails(newDetails);
    }
  }, [sent]);

  useEffect(() => {
    setLoading(true);

    (async () => {
      await api.post("/v1/chat/getlist",
        {
          page: pageList,
          rows: offset,
          lineid: lineData.id
        },
        { headers: { "x-access-token": GetToken() } }
      )
        .then((getlist) => {
          if (getlist.data.res) {
            const newlist = [ ...list, ...getlist.data.ret ]; 
            setList(newlist);
            console.log(getlist);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: getlist.data.message
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });
        }).finally(() => setLoading(false));
    })();
  }, [pageList]);

  function handleSubmit (e) {
    /*if (String(e).length === 0) {
      return;
    }*/
    e.preventDefault();

    setLoading(true);

    const data = {
      lineid: lineData.id,
      contactid: contactId,
      message: text
    };

    (async () => {
      await api.post("/v1/chat/send",
        data,
        { headers: { "x-access-token": GetToken() } }
      )
        .then((send) => {
          if (send.data.res) {
            const ret = send.data.ret;
            if (contactId !== data.contactid) {
              return;
            }

            const newData = { 
              ...data,
              id: ret.insertId,
              userid: userInfo.id,
              uname: userInfo.fname.concat(userInfo.lname ? " " + userInfo.lname : ""),
              readstatus: 0,
              createdat: "Waiting ..."
            };
      
            const newDetails = [...details, newData];

            setDetails(newDetails);
            setState(false);
            setText("");
            console.log(send);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: send.data.message
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });
        }).finally(() => setLoading(false));
    })();
  }

  function handleChange (e) {
    setText(e.target.value);
  }

  function handleShowEmoji (e) {
    setState(!state);
  }

  function handleEmoji (e) {
    //setState(false);
    setText(text + e.native);
  }

  function handleOnClick (contactid, name) {
    (async () => {
      setLoading(true);

      const newPageDetails = 1;
      setPageDetails(newPageDetails);

      await api.post("/v1/chat/getdetails",
        {
          page: newPageDetails,
          rows: offset,
          lineid: lineData.id,
          contactid: contactid
        },
        { headers: { "x-access-token": GetToken() } }
      )
        .then((getdetails) => {
          if (getdetails.data.res) {
            setDetails(getdetails.data.ret);
            console.log(getdetails);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: getdetails.data.message
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });
        }).finally(() => {
          setState(false);
          setContactId(contactid);
          setName(name);
          setLoading(false);
        });
    })();
  }

  useEffect(() => {

    if (pageDetails <= 1) {
      return;
    }

    setLoading(true);
      api.post("/v1/chat/getdetails",
        {
          page: pageDetails,
          rows: offset,
          lineid: lineData.id,
          contactid: contactId
        },
        { headers: { "x-access-token": GetToken() } }
      )
        .then((getdetails) => {
          if (getdetails.data.res) {
            const newDetails = [ ...getdetails.data.ret, ...details ];
            setDetails(newDetails);
            console.log(getdetails);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: getdetails.data.message
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });
        }).finally(() => {
          setLoading(false);
        });
  }, [pageDetails]);

  function handleContactSearch (like) {
    (async () => {

      setSearchText(like.target.value);

      if (like.target.value.length === 0) {
        setContactList([]);
        return;
      }

      await api.post("/v1/contact/getlike",
        {
          like: like.target.value,
          lineid: lineData.id
        },
        { headers: { "x-access-token": GetToken() } }
      )
        .then((getlike) => {
          if (getlike.data.res) {
            if (like.target.value.length > 0) {
              setContactList(getlike.data.ret);
            }
            console.log(getlike);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: getlike.data.message
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });
        }).finally(() => {
          //Do samething
        });
    })();
  }

  function handleShowContacts() {
    setState(false);
    setContactId(0);
    setNewConv(true);
  }

  function handleCloseContacts() {
    setState(false);
    setSearchText("");
    setContactList([]);
    setNewConv(false);
  }

  function handleShowMoreList() {
    setPageList(pageList + 1);
  }

  function handleShowMoreDetails() {
    setPageDetails(pageDetails + 1);
  }

  async function handleRefresh() {
    setLoading(true);
    setState(false);
    setContactId(0);

    handleCloseContacts();

    if (pageList > 1) {
      setList([]);
      setPageList(1);
      return;
    }

    await api.post("/v1/chat/getlist",
        {
          page: pageList,
          rows: offset,
          lineid: lineData.id
        },
        { headers: { "x-access-token": GetToken() } }
      )
        .then((getlist) => {
          if (getlist.data.res) {
            setList(getlist.data.ret);
            console.log(getlist);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: getlist.data.message
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });
        })
        .finally(() => setLoading(false));
  }

  return (
    <div className="container mt-3">
      <Loading visible={loading} />
      <div className='row mb-3'>
        <div className='col-12 col-md-12'>
            <p id="create-pix" className="title">Chat</p>
        </div>
        <div className="row">
          <div className="col-md-4 col-12 mh-100">
            <div className="form-content overflow-scroll">
              <div className="row">
                <div className="col d-flex">
                  Conversations
                </div>
                <div className="col d-flex flex-row-reverse p-0">
                  <FontAwesomeIcon data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={ handleRefresh } icon={ faRefresh } size="lg" className="btn btn-icon p-0" />
                  <FontAwesomeIcon data-toggle="tooltip" data-placement="bottom" title="New Message" onClick={ handleShowContacts } icon={ faPenToSquare } size="lg" className="btn btn-icon p-0" />
                </div>
                <hr />
              </div>
              <div className="row">
                  {list.map(listItem => {
                    return <ChatItemList contactId={ contactId } key={ listItem.id } onClick={ handleOnClick } details={{ id: listItem.id, contactid: listItem.contactid, readstatus: listItem.readstatus, userid: listItem.userid, name: listItem.name, lastmsg: String(listItem.message).length > 45 ? String(listItem.message).substring(0, 45).concat("...") : listItem.message, lastusr: listItem.userid, time: listItem.createdat }} />
                  })}
              </div>

              <div className="d-flex flex-row-reverse">
                <div className="btn btn-icon p-0" onClick={ handleShowMoreList }>Show more itens ...</div>
              </div>
            </div>
          </div>
          <div className={newConv ? "col-md-4 col-12" : "d-none"}>
            <div className="form-content overflow-scroll">
              <div className="row">
                <div className="col d-flex">
                  Contact List
                </div>
                <div className="col d-flex flex-row-reverse p-0">
                  <FontAwesomeIcon data-toggle="tooltip" data-placement="bottom" title="Close" onClick={ handleCloseContacts } icon={ faClose } size="lg" className="btn btn-icon p-0" />
                </div>
                <hr />
              </div>
              <div className="row mb-2">
                <input type="text" value={ searchText } onChange={ handleContactSearch } className="form-control shadow-none" placeholder="Search ..."></input>
              </div>
              <div className="row">
                {contactList.map(contactItem => {
                  return <ContactItemList key={ contactItem.id } onClick={ handleOnClick } details={{ id: contactItem.id, name: contactItem.name, phone: contactItem.phone, company: contactItem?.company }} />
                })}
              </div>
            </div>
          </div>
          <div className={newConv ? "col-md-4 col-12" : "col-md-8 col-12"}>
            <form className="form-content overflow-scroll" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-11 d-flex">
                  {contactId > 0 ? name : "No conversation selected!"}
                </div>
                <div className="col-1 d-flex flex-row-reverse p-0">
                  <FontAwesomeIcon data-toggle="tooltip" data-placement="bottom" title="Chat" icon={ faCommentAlt } size="lg" className="btn btn-icon p-0" />
                </div>
                <hr />
              </div>
              <div className={ contactId > 0 ? "row" : "row d-none" }>
                <div className="btn btn-icon pt-0 pb-3" onClick={ handleShowMoreDetails }>Show more itens ...</div>
              </div>
              <div className={ contactId > 0 ? "row" : "row d-none" }>
                {details.map(detailsItem => {
                  return <ChatItem key={ detailsItem.id } details={{ ...detailsItem }} />
                })}
              </div>
              <div className={ contactId > 0 ? "row" : "row d-none" }>
                <textarea 
                  type="text" 
                  value={text}
                  onChange={handleChange}
                  className="form-control shadow-none"
                  placeholder=""
                  maxLength="600"
                  rows="2" 
                />
              </div>
            
              <div className={ contactId > 0 ? "row p-0" : "row p-0 d-none" }>
                <div className="col p-0">
                  <span onClick={handleShowEmoji} className="btn btn-orange btn-confirm shadow-none mt-2 ms-0">
                    <FontAwesomeIcon icon={ faSmile } size="lg" className="btn-icon" />
                  </span>
                </div>
                <div className="col d-flex flex-row-reverse p-0">
                    <button type="submit"
                      className={ text.trim().length > 0 ? "btn btn-orange btn-confirm shadow-none mt-2" : "btn btn-orange btn-confirm shadow-none mt-2 disabled"}>
                      <FontAwesomeIcon icon={ faPaperPlane } size="lg" className="btn-icon" />
                      Send
                  </button>
                </div>
              </div>
              <span className="pt-2 m-0" style={{ display: state ? 'block' : 'none'}}>
                <Picker data={data} theme="light" onEmojiSelect={emoji => handleEmoji(emoji)} />
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}