import React from 'react';
import ReactDOM from 'react-dom';
import './assets/global.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
//import * as PusherPushNotifications from "@pusher/push-notifications-web";

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    console.log(JSON.stringify(registration));
    if (registration !== {} && registration.waiting !== {}) {
      console.log(JSON.stringify(registration.waiting));
      if (window.confirm('Uma nova versão está disponível! Pronto para atualizar?')) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        //alert("Por favor, atualize a página para carregar a nova versão!")
        /*setTimeout(() => {
          window.location.reload();
        }, 1500);*/
      }
    }
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//Pusher Notification
/*const beamsClient = new PusherPushNotifications.Client({
  instanceId: "d62d18d7-982e-4a80-816f-1f54c66f9688",
});

beamsClient.start().then(() => {
  console.log("Pusher started");
});*/
