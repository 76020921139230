import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import api, { GetToken } from "../api/axios";
import { useRecoilState } from 'recoil';
import { TenantState, LineState, MqttState } from '../state/states';

export default function User() {

  const [ userData, setUserData ] = useState();
  const [ lineData, setLineData ] = useRecoilState(LineState);
  const [ tenantData, setTenantData ] = useRecoilState(TenantState);
  const [ mqttData, setMqttData ] = useRecoilState(MqttState);
  const [ lineList, setLineList ] = useState([]);

  useEffect(() => {
    const token = GetToken();

    if (token === "null") {
      return <Navigate to="/" />;
    }
    if (!token) {
      return <Navigate to="/" />;
    }

    var userInfo = {};
    try {
      userInfo = jwt_decode(token);
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    (async () => {
      await api.get(`/v1/user/getuserdata/${userInfo.id}`, {
        headers: { "x-access-token": GetToken() },
      }).then(getuserdata => {
        setUserData(getuserdata.data.ret[0]);
        localStorage.setItem("tenant", getuserdata.data.ret[0].tenant);
        setTenantData(getuserdata.data.ret[0].tenant);
        setMqttData(getuserdata.data.ret[0]);
      }).catch(err => {
        console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(err),
          });
      });
    })();
  }, []);

  useEffect(() => {
    if (userData) {
      if (userData.lineid > 0) { //Role 2 or 3
        (async () => {
          await api.get(`/v1/line/getbyid/${userData.lineid}`, {
            headers: { "x-access-token": GetToken() },
          }).then(getbyid => {
            getbyid.data.ret[0].number = normalizeNumber(getbyid.data.ret[0].number);
            localStorage.setItem("line", JSON.stringify(getbyid.data.ret[0]));
            setLineData(getbyid.data.ret[0]);
            setLineList(getbyid.data.ret);
          }).catch(err => {
            console.log(err);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: JSON.stringify(err),
              });
          });
        })();
      } else {
        api.get("/v1/line/getall", {
          headers: { "x-access-token": GetToken() },
        }).then(getbyid => {
          const list = getbyid.data.ret.filter(item => {
            item.number = normalizeNumber(item.number);
            return item;
          })
          localStorage.setItem("line", JSON.stringify(list[0]));
          setLineData(list[0]);
          setLineList(list);
        }).catch(err => {
          console.log(err);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: JSON.stringify(err),
            });
        });
      }
    }
  }, [userData]);

  /*useEffect(() => {
    if (lineData) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: JSON.stringify(normalizeNumber(lineData.number)),
      });
    }
  }, [lineData]);*/

  function setLine(item) {
    localStorage.setItem("line", JSON.stringify(item));
    setLineData(item);
    //window.location.reload(false);
  }

  function normalizeNumber(number) {
    if (String(number).trim().length === 12) {
        const phone = "(" + String(number).substring(2, 5) + ") " + String(number).substring(5, 8) + "-" + String(number).substring(8);
        return phone;
    } else {
        return number;
    }
  }

  return (
    <ul className="navbar-nav  ms-auto flex-nowrap">
      <li className="nav-item dropdown">
        <button
          className="button-link nav-link dropdown-toggle"
          id="navbarDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={faPhoneSquare} size="lg" className="me-2" />
          <span className="me-1 small">{ lineData?.name + " •" }</span>
          <span className="me-1 small">{ lineData?.number }</span>
        </button>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          {lineList.map(item => {
            return (
              <li key={item.id}>
                <button className="dropdown-item" onClick={() => setLine(item) }>
                  <span className="me-1 small">{item?.name + " •"}</span>
                  <span className="me-1 small">{item?.number}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </li>
    </ul>
  );
}