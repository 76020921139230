import React, { useState } from "react";
import loading from "../assets/images/loading.gif";
import { faCheck, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function MaintenanceFile({ details }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className={details?.userid > 0 ? "card ps-5 pe-0" : "card ps-0 pe-5"}>
                <div className={details?.userid > 0 ? "card-body bg-light p-2 rounded mb-2" : "card-body bg-primary p-2 text-white rounded mb-2"}>
                    <div className={ details?.readstatus > 0 ? "row" : "bg-secondary" }>
                        <div className={details?.userid > 0 ? "d-flex text-right justify-content-between align-items-start" : "d-flex justify-content-between align-items-start"}>
                            { details?.media ? (
                                <a href={details?.media} target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={details?.media} alt={details?.media} /></a>
                            ) : details?.message }
                            { details?.userid === 0 ? (<div className="btn btn-icon small text-white m-0 p-0"> 
                                <FontAwesomeIcon icon={ faEllipsisVertical } aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick} />
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    <MenuItem onClick={handleClose}>My account</MenuItem>
                                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                                </Menu>
                            </div>) : ""}
                        </div>
                        <div className="d-flex small flex-row-reverse fw-lighter">
                            <div>{ (details?.userid > 0 && details?.readstatus > 0) ? (<>&nbsp;•&nbsp;<FontAwesomeIcon icon={ faCheck } alt="Sent!" title="Sent!" /></>) : "" }</div>
                            <div>{ (details?.userid > 0 && details?.readstatus === 0) ? (<>&nbsp;•&nbsp;<img src={ loading } alt="Sending ..." title="Sending ..." width={21} height={21} /></>) : "" }</div>
                            <div>&nbsp;{ (details?.createdat) }</div>
                            <div>{ (details?.userid > 0 ? String(details?.uname).concat(" •") : "") }</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
