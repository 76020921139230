import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";

import jwt_decode from "jwt-decode";
import api, { GetToken } from "../api/axios";

export default function User() {
  const history = useNavigate();
  const location = useLocation()
  const [userName, setUserName] = useState("");

  var pathName = location.pathname;

  useEffect(() => {
    const token = GetToken();

    if (token === "null") {
      return <Navigate to="/" />;
    }

    if (!token) {
      return <Navigate to="/" />;
    }

    var userInfo = {};
    try {
      userInfo = jwt_decode(token);
      setUserName(userInfo.fname);
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    if (userInfo.chgpss === 1) { //&& history.location.pathname.toLowerCase() !== 'dashboard/change-pwd') {
      history("dashboard/change-pwd");
      return null;
    }

    var dateExpiring = new Date(userInfo.exp * 1000).toLocaleString().substr(0, 10);
    var dateNow = new Date().toLocaleString().substr(0, 10);

    dateExpiring = dateExpiring.split('/')[2] + '-' + dateExpiring.split('/')[1] + '-' + dateExpiring.split('/')[0];
    dateNow = dateNow.split('/')[2] + '-' + dateNow.split('/')[1] + '-' + dateNow.split('/')[0];

    //console.log('login expiring: ', new Date(userInfo.exp * 1000).toLocaleString(), ', date now: ', new Date().toLocaleString())
    //Verify if token expired
    var dateUserInfo = new Date(userInfo.exp * 1000);
    var dateActual = new Date(Date.now());

    if (dateActual.getTime() < dateUserInfo.getTime()) {
      if (dateNow === dateExpiring) {
        api
          .post("login/refresh", {}, { headers: { "x-access-token": GetToken() } })
          .then((data) => {
            localStorage.setItem("token", data.data.token);
          })
          .catch((err) => {
            console.log('login expired, redirecting to login');
            localStorage.setItem("token", null);
            history.push("/");
          });
        return;
      }
    } else {
      console.log('login expired, redirecting to login');
      localStorage.setItem("token", null);
      history.push("/");
      return;
    }
  }, [history]);

  function Logout() {
    localStorage.setItem("token", null);
    history("/");
  }
  //const token = GetToken();
  //const userInfo = jwt_decode(token);

  return (
    <ul className="navbar-nav  ms-auto flex-nowrap">
      <li className="nav-item dropdown">
        <button
          className="button-link nav-link dropdown-toggle"
          id="navbarDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={faUser} size="lg" className="me-2" />
          <span className="me-1">{userName}</span>
        </button>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          <li>
            <Link
              className="dropdown-item"
              to="dashboard/change-pwd"
              style={
                pathName.includes("/change-pwd")
                  ? { color: "var(--button-primary" }
                  : {}
              }
            >
              Change Password
            </Link>
          </li>

          <li>
            <Link className="btn-secondary dropdown-item" 
              to="/"
              style={
                  pathName.includes("/exit")
                    ? { color: "var(--button-primary" }
                    : {}
                } onClick={Logout}>
              Logout
            </Link>
          </li>
        </ul>
      </li>
    </ul>
  );
}