import React, { useEffect, useState } from "react";
import api, { GetToken } from "../../api/axios";
import { Navigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faClipboardCheck, faMoneyBill1Wave } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

export default function List() {

  const { id } = useParams();
  //const [role, setRole] = useState();
  const [loading, setLoading] = useState(false);
  const [getList, setGetList] = useState([]);

  useEffect(() => {

    const token = GetToken();
    if (token === "null") {
      return <Navigate to="/" />;
    }

    if (!token) {
      return <Navigate to="/" />;
    }

    var userInfo = {};
    try {
      userInfo = jwt_decode(token);
      //setRole(userInfo.role);
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    if (!id) {
      return <Navigate to="/dashboard" />;
    }

    if (userInfo.chgpss === 1) {
      return null;
    }

    UpdateList();

  }, []);

  function UpdateList() {
    setLoading(true);
    
    api.get('/v1/charge/getbytxid/' + id, 
      { headers: { "x-access-token": GetToken() }
    })
      .then(getlist => {
        if (!getlist.data.res) {
        } else {
          setGetList(getlist.data.message);
        }
      }).catch(_ => {
        console.log(_);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClick(item) {
    const newList = getList.map((qrcode) => {
      if (qrcode.id === item.id) {
        return {
          ...qrcode,
          isBlur: !qrcode.isBlur
        };
      } else {
        return qrcode;
      }
    });
    setGetList(newList);
  }

  function handleCopy(item) {
    var newList = getList.map((qrcode) => {
      if (qrcode.id === item.id) {
        return {
          ...qrcode,
          isCopied: !qrcode.isCopied
        };
      } else {
        return qrcode;
      }
    });

    setGetList(newList);

    //navigator.clipboard.write(item.qrcode);
    copyToClipboard(item.qrcode);

    setTimeout(() => {
      newList = getList.map((qrcode) => {
        if (qrcode.id === item.id) {
          return {
            ...qrcode,
            isCopied: qrcode.isCopied
          };
        } else {
          return qrcode;
        }
      });
      setGetList(newList);
    }, 2000);
  }

  function copyToClipboard(str) {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);

    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        // save current contentEditable/readOnly status
        var editable = el.contentEditable;
        var readOnly = el.readOnly;

        // convert to editable with readonly to stop iOS keyboard opening
        el.contentEditable = true;
        el.readOnly = true;

        // create a selectable range
        var range = document.createRange();
        range.selectNodeContents(el);

        // select the range
        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        el.setSelectionRange(0, 999999);

        // restore contentEditable/readOnly to original state
        el.contentEditable = editable;
        el.readOnly = readOnly;
    } else {
        el.select(); 
    }

    document.execCommand('copy');
    document.body.removeChild(el);
  }

  function checkPayment(txid) {
    setLoading(true);
    
    api.get('/v1/charge/check/' + txid, 
      { headers: { "x-access-token": GetToken() }
    })
      .then(getcheck => {
        console.log(getcheck.data.message);
        if (!getcheck.data.res) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: JSON.stringify(getcheck),
          });
        } /*else {
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Seu PIX foi criado com sucesso!",
          }).then(() => {
            const cod = getcheck.data.txid
            history("/dashboard/pix/" + cod);
          });
        }*/
        UpdateList();
      }).catch(error => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.stringify(error),
        });
        console.log(error);
      })
      .finally(() => { 
        setLoading(false);
      });
  }

  function QRCode({ items, onClick, onCopy }) {
    return (
      <div className="d-flex flex-wrap col-md-12 col-12">
        {items.map((item) => (
          <div className="card text-white bg-secondary" style={{width: "20rem", margin: "10px"}} key={item.id}>
            <div className="card-header text-center">
              {!item.endtoendid ? "AGUARDANDO PAGAMENTO" : "PAGO"}
            </div>
            <div className={ "card-body text-center "  + (!item.endtoendid ? "bg-warning text-muted " : "bg-success text-white ") }>
              {!item.qrcodeimage ? <p>Sem Imagem</p> :
                <img className="card-img-top" src={ !item.qrcodeimage ? "" : "data:image/png;base64," + item.qrcodeimage } style={{ filter: item?.isBlur ? "blur(4px)" : "blur(0px)"}} type="button" onClick={() => onClick(item)} alt="QR Code" />
              }
              <span>{ item?.isBlur ? "Clique para aparecer" : "Clique para borrar" }</span>
            </div>
            <div className="card-footer">
              <p className="card-text">
                Data: {item.creation}<br />
                Valor: {parseFloat(item.amount).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}<br />
                { item.sellorder ? (<span>Descrição: {item.sellorder} <br /></span>) : null}
                { item.payerinfo ? (<span>Mensagem: {item.payerinfo} <br /></span>) : null}
                { item.personname ? (<span>Devedor: {item.personname} <br /></span>) : null}
                { item.persontype ? (<span>{item.persontype}: {item.personnumber}</span>) : null}
              </p>
              <div className="d-flex justify-content-between card-text">
                <button type="submit"
                    className={"btn btn-sm ".concat(item?.isCopied ? "bg-white" : "bg-white")}
                    onClick={ () => checkPayment(item.txid) }
                >
                    <FontAwesomeIcon id="clipboard" icon={ faMoneyBill1Wave } size="lg" className="btn-icon mr-4" />Verificar
                </button>
                <button type="submit" 
                    className={"btn btn-sm ".concat(item?.isCopied ? "bg-white" : "bg-white")}
                    onClick={() => onCopy(item, this)}
                >
                    <FontAwesomeIcon id="clipboard" icon={ item?.isCopied ? faClipboardCheck : faCopy } size="lg" className="btn-icon mr-4" />{ item?.isCopied ? "Copiado!" : "Copiar" }
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  //CORES
  //#0000BF #3F7F00 #82FF05 #FBE731 #F60404"
  return (
    <div>
      <Loading visible={loading} />
      <div className="row">
        <QRCode items={getList} onClick={ handleClick } onCopy={ handleCopy } />
      </div>
    </div>
  );
}