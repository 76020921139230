import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.png";

import api, { SaveToken } from "../api/axios";
import Loading from "../components/Loading";

import Swal from "sweetalert2";
//import { FormattedMessage } from "react-intl";

export default function Login() {
  const history = useNavigate();

  useEffect(() => {
    document.title = "LoadOut Chat App";
    if (localStorage.getItem("rememberMe") !== "null") {
      setEmail(localStorage.getItem("rememberMe"));
      setRememberMe(true);
    }
  }, []);

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  function HandleEnter(e) {
    if (e.target.id === "floatingPasswordInput" && e.key === "Enter") {
      HandleLogin();
    }
  }

  async function HandleLogin() {
    setLoading(true);
    api
      .post("/v1/login", { email: email, pass: pass })
      .then((data) => {
        SaveToken(data.data.token);

        if (rememberMe) {
          localStorage.setItem("rememberMe", email);
        } else {
          localStorage.setItem("rememberMe", null);
        }

        setLoading(false);
        history("dashboard");
      })
      .catch((err) => {
        console.log(err);

        if (err.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Invalid email or password!",
          });
        }

        if (err.response?.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
        }

        setLoading(false);
      });
  }

  return (
    <div className="container mt-5 d-flex justify-content-center flex-wrap">
      <Loading visible={loading} />
      <div className="col-md-8 col-10">
        <div className="d-flex justify-content-center mb-5 mt-5 fill">
          <img src={Logo} alt="LoadOut Chat App" className="img-fluid" />
        </div>
      </div>

      <div className="white-container col-md-6 col-10">
        <p className="container-title">LoadOut Chat App</p>

        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control"
            id="floatingEmailInput"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="email@example.com"
          />
          <label id="email_adress" htmlFor="floatingEmailInput">E-Mail</label>
        </div>

        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="floatingPasswordInput"
            value={pass}
            onChange={(e) => {
              setPass(e.target.value);
            }}
            onKeyUp={(e) => { HandleEnter(e); }}
            placeholder="Password"
          />
          <label id="password" htmlFor="floatingPasswordInput">Password</label>
        </div>

        <div className="d-flex justify-content-between small-link mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              checked={rememberMe}
              id="flexCheckDefault"
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label id="remember_me" className="form-check-label" htmlFor="flexCheckDefault">Remember Me</label>
          </div>

          <div>
            <Link to="/forgot_password" style={{ textDecoration: "none" }}>
              <label id="forgot_password">Forgot Password</label>
            </Link>
          </div>
        </div>

        <div className="d-grid gap-2 col-12 mx-auto mt-4">
          <button id="login" onClick={HandleLogin} className="btn btn-primary" >Login</button>
        </div>
      </div>
      <div className="col-md-8 col-10">
        <div className="d-flex justify-content-center mb-1 mt-1">
          <span className="small-link align-self-baseline">&copy; 2022 LoadOut Chat App - release 20221106a</span>
        </div>
      </div>
    </div>
  );
}