import { atom } from 'recoil';

const TenantState = atom({
    key: 'tenantState',
    // get initial state from local storage to enable user to stay logged in
    default: localStorage.getItem('tenant')
});

const LineState = atom({
    key: 'lineState',
    // get initial state from local storage to enable user to stay logged in
    default: JSON.parse(localStorage.getItem('line'))
});

const MqttState = atom({
    key: 'mqttState',
    // get initial state from local storage to enable user to stay logged in
    default: {}
});

export { TenantState, LineState, MqttState };