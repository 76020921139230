import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { GetToken } from "../api/axios";
import jwt_decode from "jwt-decode";

import Logo from '../assets/images/logo.png';
import Line from './Line';
import User from './User';


export default function Header() {

    const [licensed, setLicensed] = useState("");

    useEffect(() => {
        document.title = 'LoadOut Chat App';
        const token = GetToken();
        if (token === "null") {
            return <Navigate to="/" />;
        }

        if (!token) {
            return <Navigate to="/" />;
        }

        /*var userInfo = {};
        try {
            userInfo = jwt_decode(token);
            //setLicensed(userInfo.fantasia);
        } catch (error) {
            console.log('👾 invalid token format', error);
            return true;
        }*/

        /*if (userInfo.chgpss === 1) {
            return null;
        }*/

        

    }, []);

    function reload() {
        const userInfo = jwt_decode(GetToken());
        setTimeout(() => {
            setLicensed(userInfo.fantasia);
        }, 1);
    }

    const noMenuPages = [ '', 'login', 'forgot_password', 'define']

    var pathName = useLocation().pathname;

    if(noMenuPages.includes(pathName.split('/')[1])){
        return null;
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-dark">
            <script>{reload()}</script>
            <div className="container-fluid">
                <a className="navbar-brand pt-0 pb-0" href="#/dashboard">
                    <img src={Logo} width='50' alt="LoadOut Chat App" className="d-inline-block" />
                </a>
                LoadOut Chat App{licensed}
                <button id='navbar-toggler' className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" >
                    <Line />
                    <User />
                </div>
            </div>
        </nav>
    );
}