import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faClipboardQuestion, faComments, faHome, faLock, faSignOutAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { GetToken } from "../api/axios";
import jwt_decode from "jwt-decode";

export default function LeftMenu() {
  const history = useNavigate();
  const [configClicked, setConfigClicked] = useState(false);

  useEffect(() => {

    try {
      GetToken();
    } catch (error) {
      console.log('👾 invalid token format', error);
      return true;
    }

    /*api
      .get("param-country/getall", {
        headers: { "x-access-token": GetToken() },
      })
      .then((data) => {
        
      });*/
  }, [history]);

  const menusAdmin = [
    {
      name: "Dashboard",
      icon: faHome,
      page: "/dashboard",
    },
    {
      name: "Chat",
      icon: faComments,
      page: "chat",
    },
    {
      name: "Templates",
      icon: faClipboardQuestion,
      page: "list",
    },
    {
      name: "Contacts",
      icon: faAddressBook,
      page: "report",
    },
    {
      name: "Users",
      icon: faUsers,
      page: "user",
    }

  ];

  const menusOperator = [
    {
      name: "Dashboard",
      icon: faHome,
      page: "/dashboard",
    },
    {
      name: "Chat",
      icon: faComments,
      page: "chat",
    },
    {
      name: "Templates",
      icon: faClipboardQuestion,
      page: "list",
    },
    {
      name: "Contacts",
      icon: faAddressBook,
      page: "report",
    }

  ];

  const noMenuPages = ["", "login", "forgot_password", "define"];

  var pathName = useLocation().pathname;

  if (noMenuPages.includes(pathName.split("/")[1])) {
    return null;
  }

  /*function Logout() {
    localStorage.setItem("token", null);
    history.push("/");
  }*/

  const token = GetToken();
  if (token === "null") {
    return <Navigate to="/" />;
  }

  if (!token) {
    return <Navigate to="/" />;
  }

  var userInfo = {};
  try {
    userInfo = jwt_decode(token);
    jwt_decode(token);
  } catch (error) {
    console.log('👾 invalid token format', error);
    return true;
  }

  function Logout() {
    localStorage.setItem("token", null);
    history("/");
  }

  function Change() {
    history("/dashboard/change-pwd");
  }

  if (userInfo.role === 0 || userInfo.role === 1 || userInfo.role === 2) {
    return (
      <div className="col-md-2">
        <nav
          className="navbar navbar-dark bg-dark pt-3 collapse left-menu"
          id="navbarSupportedContent"
          style={{ alignItems: "baseline" }}
        >
          <div className="container" id="leftMenusContainer">
            {( userInfo.role === 0 ? menusAdmin : menusOperator).map((menu) => {
              const isSelected =
                pathName.endsWith(menu.page);
              return (
                <Link
                  className="menu-item-container"
                  to={menu.page}
                  key={menu.page}
                  onClick={() => {
                    setConfigClicked(false);
                    document
                      .getElementById("navbarSupportedContent")
                      ?.classList.remove("show");
                  }}
                  style={
                    isSelected && !configClicked
                      ? {
                        backgroundColor: "var(--button-primary)",
                        color: "white",
                      }
                      : { color: "black" }
                  }
                >
                  <div className="col-4 col-sm-3">
                    <FontAwesomeIcon
                      icon={menu.icon}
                      alt="Menu icon"
                      color={isSelected && !configClicked ? "white" : "black"}
                      size="2x"
                    />
                  </div>
                  <div className="col-8 col-sm-9 d-block hide-mobile">
                    <span className="text-left float-start">{menu.name}</span>
                  </div>
                </Link>
              );
            })}
            <div
              className="container menu-item-container btn d-inline-flex align-items-center rounded collapsed ps-0 pe-0 hide-desktop show-mobile"
              data-bs-toggle="collapse"
              data-bs-target="#account-collapse"
              aria-expanded="false"
            >
              <Link
                className="menu-item-container"
                to="change-pwd"
                onClick={() => {
                  setConfigClicked(false);
                  document
                    .getElementById("navbarSupportedContent")
                    ?.classList.remove("show");
                }}
                style={
                  pathName.endsWith("change-pwd") && !configClicked
                    ? {
                      backgroundColor: "var(--button-primary)",
                      color: "white",
                    }
                    : { color: "black" }
                }
              >
                <div className="col-4 col-sm-3">
                  <FontAwesomeIcon
                    icon={faLock}
                    color={pathName.endsWith("change-pwd") && !configClicked ? "white" : "black"}
                    size="2x"
                    alt="Menu icon"
                  />
                </div>
                <div className="col-8 col-sm-9 d-block hide-mobile">
                  <span className="text-left float-start" onClick={ () => { 
                    Change();
                    document.getElementById("navbarSupportedContent")
                      ?.classList.remove("show"); }}>
                    Change Password
                  </span>
                </div>
              </Link>
            </div>
            <div
              className="container menu-item-container btn d-inline-flex align-items-center rounded collapsed ps-0 pe-0 hide-desktop show-mobile"
              data-bs-toggle="collapse"
              data-bs-target="#account-collapse"
              aria-expanded="false"
            >
              <Link
                className="menu-item-container"
                to="/"
                onClick={() => {
                  Logout();
                }}
              >
                <div className="col-4 col-sm-3">
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    size="2x"
                    alt="Menu icon"
                  />
                </div>
                <div className="col-8 col-sm-9 d-block hide-mobile">
                  <span className="text-left float-start">
                    Logout
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}