import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

import List from "./list";
import AddEdit from "./addEdit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function Users() {
  const history = useNavigate();
  const [search, setSearch] = useState("");
  var pathName = useLocation().pathname;

  function CreateUser() {
    history("add");
  }

  useEffect(() => {
    document.title = "LoadOut Chat - Users";
  }, []);

  return (
    <Routes>
      <Route path="*" element={ <User /> } />
    </Routes>
  );


  function User() {
    return (
      <div className="container mt-3">
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <p className="title">
              Users
            </p>
          </div>
          {(!pathName.includes('add') && !pathName.includes('edit')) && <div className="col-md-6 row">
            <div className="col-6 col-md-8">
              <input
                  className="form-control"
                  autocomplete="off"
                  placeholder="Search..."
                  onChange={ (e) => setSearch(e.target.value) }
                  value={search}
                  autoFocus={search && true}
              />
            </div>
            <div className="col-6 col-md-4">
              <button className="btn btn-orange btn100" onClick={ (e) => CreateUser() }>
                <FontAwesomeIcon icon={faPlus} className="btn-icon" />
                New
              </button>
            </div>
          </div> 
          }
        </div>
        <Routes>
          <Route index element={ <List searchTerm={ search } /> }/>
          <Route path="add" element={ <AddEdit /> } />
          <Route path="edit/:id" element={ <AddEdit /> } />
        </Routes>
      </div>
    );
  }
}