import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import api, { GetToken } from "../api/axios";
import Swal from "sweetalert2";
import { faSave, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwt_decode from "jwt-decode";

const eye = <FontAwesomeIcon icon={faEye} />;

export default function ChangePassword() {

    const [currentPassword, setCurrentPassword] = useState('');
    const [isRevealCurrentPassword, setIsRevealCurrentPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [isRevealNewPassword, setIsRevealNewPassword] = useState(false);
    const [chgpss, setChgpss] = useState(false);
    
    
    useEffect(() => {

        const token = GetToken();
        if (token === "null") {
            return <Navigate to="/" />;
        }

        if (!token) {
            return <Navigate to="/" />;
        }

        var userInfo = {};
        try {
            userInfo = jwt_decode(token);
            if (userInfo.chgpss === 1) {
                setChgpss(true);
            }
        } catch (error) {
            console.log('👾 invalid token format', error);
            return true;
        }

        if (userInfo.chgpss === 1) {
            return null;
        }
        
    }, [chgpss]);
    

    function onSubmit(e) {
        e.preventDefault();
        api
            .post(
                "/v1/login/change",
                {
                    oldpass: currentPassword,
                    newpass: newPassword,
                },
                { headers: { "x-access-token": GetToken() } }
            )
            .then((data) => {
               
                Swal.fire({
                    icon: "success",
                    title: "Sucesso",
                    text: "Senha alterada com sucesso!",
                }).then(() => {
                    setCurrentPassword('');
                    setIsRevealCurrentPassword(false);
                    setNewPassword('');
                    setIsRevealNewPassword(false);
                    localStorage.setItem("token", data.data.token);
                });
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: err.response.data.message,
                });
            });
    }

    return (
        <div className="container mt-3">
            <div className='row mb-3'>
                <div className='col-12 col-md-12'>
                    <p id="change-password" className="title">Change Password</p>
                </div>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <form onSubmit={onSubmit} className="form-content">
                            <div className="row">
                                <div className="d-flex justify-content-center h5 text-danger">
                                    {chgpss ? "Diretiva de troca de senha obrigatória!" : ""}
                                </div>
                                <div id="current-password" className="col-md-12 mt-3">
                                    Old Password
                                    <div className="pwd-container">
                                        <input
                                            name="currentPassword"
                                            type={isRevealCurrentPassword ? "text" : "password"}
                                            value={currentPassword}
                                            className="form-control"
                                            onChange={e => setCurrentPassword(e.target.value)}
                                        />
                                        <i
                                            onClick={() => setIsRevealCurrentPassword(prevState => !prevState)}
                                        >{eye}</i>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div id="new-password" className="col-md-12 mt-3">
                                    New Password
                                    <div className="pwd-container">
                                        <input
                                            name="newPassword"
                                            type={isRevealNewPassword ? "text" : "password"}
                                            value={newPassword}
                                            className="form-control"
                                            onChange={e => setNewPassword(e.target.value)}
                                        />
                                        <i
                                            onClick={() => setIsRevealNewPassword(prevState => !prevState)}
                                        >{eye}</i>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row-reverse bd-highlight">
                                <button type="submit"
                                    className="btn btn-orange btn-confirm float-end"
                                >
                                    <FontAwesomeIcon id="continue" icon={faSave} size="lg" className="btn-icon mr-4" />
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}