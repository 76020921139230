import React from "react";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function MaintenanceFile({ details, onClick, contactId }) {
    //<span className={details?.readstatus > 0 ? "d-none" : "ps-2 pe-2 bg-danger text-white badge rounded-pill badge-danger center" }>
    //                            1+
    //                        </span>
    //<FontAwesomeIcon icon={ faComment } size="lg" className={details?.readstatus > 0 ? "d-none" : "btn-icon text-danger"} />

    return (
        <>
            <div className="card p-0">
                <div className={ details.contactid === contactId ? "card-body bg-secondary text-white border-2 rounded mb-2" : "card-body bg-light mb-2"}>
                    <div className="row small">
                        <div className="col-6 d-flex">
                            <span className="fw-bold">{details?.name}</span>
                        </div>
                        <span className="col-6 d-flex flex-row-reverse fw-light">{details?.time}</span>
                    </div>
                    <div className="row small">
                        <span className="fw-light">{details?.lastmsg}</span>
                    </div>
                    <div className="row small">
                        <span className="col-6 d-flex fw-lighter">Last reply: {details?.lastusr}
                            <a onClick={() => onClick(details?.contactid, details?.name) } className="btn stretched-link" />
                        </span>
                        <span className="col-6 d-flex flex-row-reverse">
                            <FontAwesomeIcon data-toggle="tooltip" data-placement="bottom" title="Unread Messages" icon={ faCommentDots } size="lg" className={(details?.readstatus === 0 && details?.userid === 0) ? "m-0 btn-icon text-danger" : "d-none" } />
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
