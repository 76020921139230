import React from "react";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function ContactItemList({ details, onClick }) {
    
    return (
        <>
            <div className="card p-0">
                <div className="card-body bg-light mt-2">
                    <div className="row align-items-center">
                        <div className="col-1">
                            <div className="row">
                                <FontAwesomeIcon icon={ faCircleUser } size="lg" className="btn-icon ps-2" />
                            </div>
                        </div>
                        <div className="col-11">
                            <div className="row small">
                                <div className="col-6 d-flex fw-bold">{details?.name}</div>
                            </div>
                            <div className="row small">
                                <span className="col-11 d-flex">Phone: {details?.phone}</span>
                                <span className="col-1 d-flex flex-row-reverse"><a onClick={() => onClick( details?.id, details?.name )} className="btn stretched-link" /></span>
                            </div>
                            <div className={ details?.company === null ? "d-none" : "row small"}>
                                <span className="col-12 d-flex">{details?.company === null ? "Company: " : "Company: " + details?.company}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
